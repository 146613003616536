import React from 'react';
import RoutingUtil from '../util/RoutingUtil';

export const AuthenticatedDataContext = React.createContext('authenticatedData');
export const useAuthenticatedData = () => React.useContext(AuthenticatedDataContext);

class AuthenticatedDataExtractor {
  constructor({ session, data, pathSiteId, pathGroupId }) {
    this._session = session;
    this._data = data;
    this._pathSiteId = pathSiteId;
    this._pathGroupId = pathGroupId;
    this._extractedData = this._extractData();
  }

  getData() {
    return this._extractedData;
  }

  getBaseUrl() {
    const { accountId, site, group } = this._extractedData;

    let path = "";
    if (site?.id) {
      path = `/sites/${site.id}`
    } else if (group?.id) {
      path = `/groups/${group.id}`;
    }

    return path !== "" ? RoutingUtil.ensurePathStartsWithAccount(path, accountId) : "/";
  }

  refreshSession(session) {
    this._session = session;
    this._extractedData = this._extractData();
  }

  _extractData() {
    const extractedData = {
      accountId: this._determineAccountId(),
      accounts: this._data?.accounts?.nodes || [],
      sites: this._data?.sites?.nodes || [],
      groups: this._data?.groups?.nodes || [],
      account: null,
      site: null,
      group: null,
      customDomain: null,
      usesSsoId: null,
      agency: this._data?.accountAgency,
      agencyId: this._data?.accountAgency?.id,
      agencyRole: this._session?.agencyRole,
      currentUser: {
        ...this._data?.currentUser,
        ...{
          avatar: {
            color: this._session?.avatarColor,
            url: this._session?.avatarUrl,
          }
        }
      },
      accountRole: this._session?.accountRole,
      accountIssuesFilter: this._session?.accountIssuesFilter,
      groupMemberships: this._data?.currentUser?.groupMemberships || [],
      siteMemberships: this._data?.currentUser?.siteMemberships || [],
    };

    extractedData.account = extractedData.accounts.find(account => account.id === extractedData.accountId) || { id: extractedData.accountId };
    extractedData.featureFlags = extractedData.account.featureFlags || [];
    extractedData.site = this._determineSite(extractedData.sites);
    extractedData.siteId = extractedData.site?.id;
    extractedData.group = this._determineGroup(extractedData.groups);
    extractedData.groupId = extractedData.group?.id;
    extractedData.customDomain = this._determineCustomDomain(extractedData.account);
    extractedData.usesSsoId = extractedData.customDomain?.usesSsoId;

    return extractedData;
  }

  _determineAccountId() {
    return this._session?.accountId || this._data?.currentShare?.account?.id;
  }

  _determineSite(sites) {
    return this._pathSiteId ? sites.find(site => site.id === this._pathSiteId) : null;
  }

  _determineGroup(groups) {
    return this._pathGroupId ? groups.find(group => group.id === this._pathGroupId) : null;
  }

  _determineCustomDomain(account) {
    return account.customDomain || { usesSsoId: false };
  }
}

export default AuthenticatedDataExtractor;