import isUrl from 'validator/lib/isURL';

const ACCEPT_INVITATION_REGEX = new RegExp('/accept-invitation/([^/]+)');
const MATCH_HANDSHAKE_REGEX = new RegExp('/handshake/([^/]+)');
const MATCH_PAGE_VIEW_MODE_REGEX = new RegExp("/sites/[0-9a-z]+/pages/([0-9a-z]+)/view/([a-z]+)");

export default class URLUtil {
  static isValidURL = (s, options = { }) => {
    return isUrl(s, options)
  }

  static extractIdAfterSubdirectory = (subDirectory, pathname) => {
    const match = new RegExp(`/${subDirectory}/([0-9a-z]+)`).exec(pathname);
    return match && match[1] !== 'create' ? match[1] : '';
  }

  static matchAcceptInvitation = (pathname) => {
    return ACCEPT_INVITATION_REGEX.exec(pathname);
  }

  static matchHandshake = (pathname) => {
    return MATCH_HANDSHAKE_REGEX.exec(pathname);
  }

  static matchPageViewMode = (pathname) => {
    return MATCH_PAGE_VIEW_MODE_REGEX.exec(pathname);
  }
}
