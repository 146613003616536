// https://www.gatsbyjs.org/tutorial/authentication-tutorial/#authentication-service

import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import { determineCurrentCustomDomain } from "./custom_domains";
import BrowserUtil from '../util/BrowserUtil';
import apollo from '../../plugins/gatsby-plugin-top-layout/apollo';

// technically we just need to kill localStorage to logout,
// but we track log out events on the backend.
const LogoutMutation = gql`
  mutation LogoutMutation {
    signOut {
      status
    }
  }
`;

// HACK: for single-sign-on accounts we don't want the session to persist
// across browser sessions, we want them to sign back in every time to
// ensure they are still a valid user in the remote system.
//
// Upon logging in, we set a cookie (named sso-good) that will disappear
// when the browser is closed, letting us know not to load the localStorage.
const isSessionExpired = () => determineCurrentCustomDomain() && !Cookies.get("sso-good");

export const getSession = () => BrowserUtil.isBrowser() && !isSessionExpired() ? BrowserUtil.getItemFromLocalStorage('dubbotSession') : {};

const setSession = session => {
  determineCurrentCustomDomain() && Cookies.set("sso-good", 1, { sameSite: "Strict" });
  BrowserUtil.setItemInLocalStorage("dubbotSession", session);
}

const getDefaultAccountByCustomDomain = accountRoles => {
  const customDomain = determineCurrentCustomDomain();
  return customDomain && accountRoles.find(r => r.customDomain === customDomain)
}

export const handleLogin = ({ accessToken, refreshToken, rememberMe, userId, userEmail, userName, avatarUrl, avatarColor, userSettings, accountId, accountRoles, accountFeatures, agencyId, agencyRole, agencyName, isSuperUser, handshakeLogoutUrl }) => {
  const currentAccount = (accountId && accountRoles.find(r => r.id === accountId)) ||
    getDefaultAccountByCustomDomain(accountRoles) ||
    accountRoles.find(r => !r.customDomain) ||
    accountRoles[0];

  const accountRole = currentAccount && currentAccount.role;
  const accountIssuesFilter = currentAccount && currentAccount.issuesFilter;
  accountId = currentAccount && currentAccount.id;

  return setSession({
    accessToken,
    refreshToken,
    rememberMe,
    userId,
    userEmail,
    userName,
    avatarUrl,
    avatarColor,
    userSettings,
    accountId,
    accountRole,
    accountIssuesFilter,
    accountRoles,
    accountFeatures,
    agencyId,
    agencyRole,
    agencyName,
    isSuperUser,
    handshakeLogoutUrl,
  });
}

export const switchAccount = (accountId, onSwitchCallback) => {
  console.debug(`Switching to account ${accountId}`);

  const session = getSession();
  const account = (session.accountRoles || []).find(a => a.id === accountId);
  const accountRole = account && account.role;
  const accountIssuesFilter = account && account.issuesFilter;
  const newSession = { ...session, accountId, accountRole, accountIssuesFilter };

  onSwitchCallback && onSwitchCallback(newSession);
  return setSession(newSession);
};

export const updateAccessToken = (accessToken) => {
  return setSession({
    ...getSession(),
    accessToken,
  });
}

export const updateIssuesFilter = (accountId, filter) => {
  const session = getSession();
  const accountRoles = session.accountRoles.map(r => ({
    ...r,
    issuesFilter: r.id === accountId ? filter : r.issuesFilter,
  }));

  return setSession({
    ...session,
    accountIssuesFilter: filter,
    accountRoles: accountRoles,
  })
}

export const isLoggedIn = () => {
  const tokens = getSession();
  return !!tokens?.accessToken;
}

export const logout = callback => {
  const session = getSession();

  const callbackWrapper = () => {
    setSession({});
    Cookies.remove("sso-good");
    callback(session)
  }

  apollo.mutate({
    mutation: LogoutMutation,
    context: {
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
      },
    },
  }).then(callbackWrapper).catch(callbackWrapper);
}

export const updateUserSettingInSession = (key, value) => {
  const session = getSession();
  const { userSettings } = session;
  if (userSettings) {
    userSettings[key] = value;
    setSession({ ...session, ...{ userSettings } });
  }
};

export const syncUserSettingsToSession = (userSettings) => {
  if (userSettings) {
    setSession({ ...getSession(), ...{ userSettings } });
  }
};

export const isUserSettingEnabled = (key) => {
  const { userSettings } = getSession();
  return userSettings && !!userSettings[key];
};
