import { navigate } from 'gatsby';
import { IntercomAPI } from './Intercom';
import RoutingUtil from '../util/RoutingUtil';

export default function trackedNavigate(href, forceReload = false, state = {}) {
  if (window.confirmUnsavedChangesMsg && !window.confirm(window.confirmUnsavedChangesMsg)) {
    return;
  }

  window.confirmUnsavedChangesMsg = null;

  const newHref = RoutingUtil.ensurePrefixedPathFromSession(href);
  if (forceReload) {
    window.location.href = newHref;
  } else {
    IntercomAPI('update', { last_request_at: parseInt((new Date()).getTime()/1000) });
    navigate(newHref, { state });
  }
};
