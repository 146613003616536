export default class BrowserUtil {
  static isBrowser = () => typeof window !== "undefined";

  static getHostname = () => window?.location?.hostname;

  static getItemFromLocalStorage = (key) => {
      try
      {
        return JSON.parse(localStorage.getItem(key) || '{}');
      }
      catch(e)
      {
        return {};
      }
  }

  static setItemInLocalStorage = (key, value) => {
    try
    {
      localStorage.setItem(key, JSON.stringify(value));
    }
    catch(e)
    {
    }
  }

  static getItemFromSessionStorage = (key) => {
      try
      {
          return JSON.parse(sessionStorage.getItem(key) || '{}');
      }
      catch(e)
      {
        return {};
      }
  }

  static setItemInSessionStorage = (key, value) => {
    try
    {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
    catch(e)
    {
    }
  }

  static async copyText(text) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  }
}