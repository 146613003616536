import BrowserUtil from '../util/BrowserUtil';

/*
Example custom domain patterns:
- samltest.dubbot.com
- beta-ui-samltest.dubbot.com

Example non-custom domain patterns:
- beta-ui.dubbot.com
- beta-ui-app.dubbot.com
*/
const patterns = [
  new RegExp('^(app.)?dubbot.'),
  new RegExp('.+.beta.dubbot.com'),
  new RegExp('.+.staging.dubbot.com'),
  'dev.dubbot.com',
  'localhost',
  'staging-app.dubbot.com'
];

export const isCustomDomain = (hostname) => {
  return hostname && !patterns.some(pattern =>
    typeof pattern === 'string' ? pattern === hostname : pattern.test(hostname)
  );
};

export const determineCustomDomain = (hostname) => isCustomDomain(hostname) && hostname;

export const determineCurrentCustomDomain = () => {
  const hostname = BrowserUtil.getHostname();
  const customDomain = determineCustomDomain(hostname) || ""
  return customDomain
}

export const isDomainChangeRequired = (account) => {
  return account.customDomain?.singleSignOnRequired &&
    account.customDomain.name !== window.location.hostname;
};

export const switchAccountViaDomainChange = (account, path) => {
  const { protocol, port } = window.location;
  const { customDomain } = account;

  const url = new URL(`${protocol}//${customDomain.name}`);
  if (port) {
    url.port = port;
  }
  url.pathname = path;

  const { href } = url;
  console.log(`Switching Account requires a domain change: ${href}`);
  window.location.href = href;
};
