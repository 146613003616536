import { getSession, switchAccount } from '../services/auth';
import trackedNavigate from '../services/trackedNavigate';
import URLUtil from './URLUtil';

export default class RoutingUtil {
  static switchToAccount = (accountId, onSwitchCallback) => {
    if (accountId) {
      switchAccount(accountId, onSwitchCallback); // Refreshes the session
      const newPath = this.ensurePathStartsWithAccount("/switch-account", accountId);
      trackedNavigate(newPath, false, { accountId });
    }
  }

  static switchToSite = (siteId, accountId) => {
    if (siteId) {
      const newPath = this.ensurePathStartsWithAccount(`/sites/${siteId}`, accountId)
      trackedNavigate(newPath, false, { siteId });
    }
  }

  static switchToGroup = (groupId, accountId) => {
    if (groupId) {
      const newPath = this.ensurePathStartsWithAccount(`/groups/${groupId}`, accountId)
      trackedNavigate(newPath, false, { groupId });
    }
  }

  static ensurePrefixedPathFromSession = (path) => {
    if (!path) {
      return "";
    }

    // Handle integer paths such as -1 for popping the history stack
    if (Number.isInteger(path)) {
      return path;
    }

    let newPath = path;
    const session = getSession();

    if (session?.agencyId && newPath.startsWith('/agency')) {
      newPath = this.ensurePathStartsWithAgency(newPath, session?.agencyId);
    } else if (session?.accountId) {
      newPath = this.ensurePathStartsWithAccount(newPath, session?.accountId);
    }

    return newPath;
  }


  static ensurePathStartsWithAccount = (originalPath, accountId) => {
    if (!accountId || Number.isInteger(originalPath)) {
      return originalPath;
    }

    const pathMappings = {
      '/switch-account': `/a/${accountId}`,
      '/account': `/a/${accountId}/settings`,
      '/settings': `/a/${accountId}/settings`,
      '/dashboards': `/a/${accountId}/dashboards`,
      '/uptime': `/a/${accountId}/uptime`,
      '/profile': `/a/${accountId}/profile`,
      '/groups': `/a/${accountId}/groups`,
      '/sites': `/a/${accountId}/sites`,
      '/tasks': `/a/${accountId}/tasks`
    };
    const oldPath = Object.keys(pathMappings).find((key) => originalPath.startsWith(key));

    if (oldPath) {
      return originalPath.replace(oldPath, pathMappings[oldPath]);
    }

    return originalPath;
  }

  static ensurePathStartsWithAgency = (originalPath, agencyId) => {
    if (!agencyId || Number.isInteger(originalPath)) {
      return originalPath;
    }

    if (originalPath === '/agency') {
      return `/agency/${agencyId}`;
    }

    const pathMappings = {
      '/agency/users': `/agency/${agencyId}/users`,
      '/agency/accounts': `/agency/${agencyId}/accounts`,
    };
    const oldPath = Object.keys(pathMappings).find((key) => originalPath.startsWith(key));

    if (oldPath) {
      return originalPath.replace(oldPath, pathMappings[oldPath]);
    }

    return originalPath;
  }

  static extractIdsFromPath = (pathname) => ({
    accountId: URLUtil.extractIdAfterSubdirectory("a", pathname),
    siteId: URLUtil.extractIdAfterSubdirectory("sites", pathname),
    groupId: URLUtil.extractIdAfterSubdirectory("groups", pathname)
  });
};